import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"

import LayoutCourse from "../../../../../layouts/course"

import CourseFooterNext from "../../../../../components/course-footer-next"
import Divider from "../../../../../components/divider"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import LetterNote from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-note"
import LetterSidebar from "../../../../../content/kurse/reiseerinnerung/01-suedfrankreich/letter-sidebar"
import MoodBarometer from "../../../../../content/kurse/haelfte-des-lebens/02-entstehungsgeschichte/mood-barometer"
import AudioPlayer from "../../../../../components/audio-player"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/stimmungsbarometer/zusammenfassung" />
      }
    >
      <Seo title="Hölderlin im Stimmungsbarometer" />
      <Stack>
        <Heading as="h2" level={2}>
          Hölderlin im Stimmungsbarometer
        </Heading>
        <LetterNote />
        <LetterSidebar
          before={<AudioPlayer src={`kurse/haelfte-des-lebens/brief-3`} />}
          main={
            <Stack space={6}>
              <p>
                Brief an den Freund Casimir von Böhlendorf, den Hölderlin am 4.
                Dezember 1801, zwei Tage vor seinem Aufbruch nach Frankreich
                verfasst. Zu diesem Zeitpunkt ist Hölderlin 31 Jahre alt.
              </p>
              <Divider size={4} />
              <p>Lieber Bruder!</p>
              <p>
                Von mir selber und wie es mir gegangen ist bisher [...] davon
                will ich mit nächstem Dir aus der Nachbarschaft Deines Spaniens,
                nämlich aus Bordeaux schreiben, wohin ich als Hauslehrer und
                Privatprediger in einem deutsch evangelischen Hauße nächste
                Woche abreise...
              </p>
              <p>
                O Freund! die Welt liegt heller vor mir, als sonst, und ernster.
                [...] Denn unter allem, was ich schauen kann von Gott, ist
                dieses Zeichen mir das auserkorene geworden. Sonst konnt‘ ich
                jauchzen über eine neue Wahrheit, eine bessere Ansicht deß, das
                über uns und um uns ist, jetzt fürcht‘ ich, daß es mir nicht
                geh‘ am Ende, wie dem alten Tantalus, dem mehr von Göttern ward,
                als er verdauen konnte.
              </p>
              <p>
                Aber ich thue, was ich kann, so gut ichs kann, und denke, wenn
                ich sehe, wie ich auf meinem Wege auch dahin muß wie die andern,
                daß es gottlos ist und rasend, einen Weg zu suchen, der vor
                allem Anfall sicher wäre...
              </p>
              <p>
                Und nun leb wohl, mein Theurer! bis auf weiteres. Ich bin jezt
                voll Abschieds. Ich habe lange nicht geweint. Aber es hat mich
                bittre Thränen gekostet, da ich mich entschloß, mein Vaterland
                noch jezt zu verlassen, vieleicht auf immer. Denn was hab‘ ich
                lieberes auf der Welt? Aber sie können mich nicht brauchen.
                Deutsch will und muß ich übrigens bleiben, und wenn mich die
                Herzens- und die Nahrungsnoth nach Otaheiti triebe.
              </p>
            </Stack>
          }
          sidebar={
            <MoodBarometer
              onChange={({ state }) => {
                setAnswer({
                  courseId: "haelfte-des-lebens",
                  chapterId: "02-entstehungsgeschichte",
                  taskId: "stimmungsbarometer-3",
                  answer: state,
                })
              }}
            />
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
